
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@use "sass:map";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$supplier-universe-ui-primary: mat.define-palette(mat.$red-palette, 800, 400, 900);
$supplier-universe-ui-accent: mat.define-palette(mat.$gray-palette, 900);


// The warn palette is optional (defaults to red).
$supplier-universe-ui-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$supplier-universe-ui-theme: mat.define-light-theme((
  color: (
    primary: $supplier-universe-ui-primary,
    accent: $supplier-universe-ui-accent,
    warn: $supplier-universe-ui-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($supplier-universe-ui-theme);

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.lite-shadow {
  box-shadow: 2px 2px 5px 2px rgb(0 0 0 / 10%);
}

@mixin width-utilities($values...) {
  @each $value in $values {
      $unit: "px";
      .w-#{$value} {
          width: #{$value}#{$unit};
          min-width: #{$value}#{$unit};
      }
  }
}

@include width-utilities(5, 10, 25, 30, 50, 75, 100, 125, 150, 175, 200, 225, 250, 275, 300, 325, 350, 375, 400);

.app-alert-box {
  padding: 0 !important;
  &.alert-success {
      background-color: map.get(mat.$green-palette, 800) !important;
      color: map.get(mat.$green-palette, contrast, 800) !important;
  }
  &.alert-error {
      background-color: map.get(mat.$red-palette, 700) !important;
      color: map.get(mat.$red-palette, contrast, 700) !important;
  }
  &.alert-notification {
      background-color: map.get(mat.$indigo-palette, 400) !important;
      color: map.get(mat.$indigo-palette, contrast, 400) !important;
  }
}